import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { CustomJsService } from "src/app/shared/custom-js.service";
import { LoginStageService } from "./../../shared/login-stage.service";
import { SportService } from "src/app/sports/sport.service";
import { UsersService } from "src/app/users/users.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { AppConfigService } from "src/app/app-config.service";

@Component({
    selector: "app-sidemenu",
    templateUrl: "./sidemenu.component.html",
    styleUrls: ["./sidemenu.component.scss"],
})
export class SidemenuComponent implements OnInit {
    public loggedIn: boolean;
    public sports: any = null;
    public user: any = null;
    public apiUrl: any = null;

    constructor(
        private config: AppConfigService,
        private customJsService: CustomJsService,
        private loginStageService: LoginStageService,
        private sportService: SportService,
        private userService: UsersService,
        private localStorage: LocalStorage
    ) {
        this.apiUrl = this.config.load();
        this.loginStageService.isLoggedIn$.subscribe((data) => {
            this.loggedIn = data;
            if (this.loggedIn) {
                this.getUser();
                this.getSport();
            }
        });
    }

    ngOnInit() {}

    getSport() {
        this.localStorage.getItem("user").subscribe((data) => {
            // this.sportService.getSports(data).subscribe((res) => {
            //     this.sports = res;
            // });
        });
    }

    getUser() {
        this.localStorage.getItem("user").subscribe((data) => {
            this.userService.getUser(data).subscribe((response) => {
                this.user = response;
            });

            setTimeout(() => {
                this.customJsService.init();
            }, 500);
        });
    }

    logout() {
        this.loginStageService.setStage(false);
        this.userService.logout();
    }
}
