import { Component, OnInit } from "@angular/core";
import { UsersService } from "../users.service";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CustomJsService } from "src/app/shared/custom-js.service";
import { SportService } from "src/app/sports/sport.service";

@Component({
    selector: "app-all-users",
    templateUrl: "./all-users.component.html",
    styleUrls: ["./all-users.component.scss"],
})
export class AllUsersComponent implements OnInit {
    public users: any = null;

    constructor(
        private localStorage: LocalStorage,
        private userService: UsersService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private customJsService: CustomJsService,
        private sportService: SportService
    ) {
        this.spinner.show();
        this.userService.getUsers().subscribe((res) => {
            this.users = res;
            setTimeout(() => {
                this.spinner.hide();
                this.initDataTable();
            }, 500);
        });
    }

    ngOnInit() {}

    initDataTable() {
        const usersTable: any = document.getElementById("user-table");
        this.customJsService.dataTableDefault(usersTable);
    }

    exportUserAthletes(soprt_id, user_id: number) {
        this.sportService.exportSportAthletes(soprt_id).subscribe((data) => {
            console.log(data);
        });
    }
}
