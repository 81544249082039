import { InternalErrorComponent } from "./errorpage/internal-error/internal-error.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./users/login/login.component";

import { DashboardComponent } from "./dashboard/dashboard/dashboard.component";
import { NotfoundComponent } from "./errorpage/notfound/notfound.component";
import { UnauthorizedComponent } from "./errorpage/unauthorized/unauthorized.component";
import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";
import { GusetGuardService } from "./auth/guest-guard.service";

const routes: Routes = [
    {
        path: "login",
        component: LoginComponent,
        canActivate: [GusetGuardService],
    },
    {
        path: "dashboard",
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: "sport",
        loadChildren: "./sports/sports.module#SportsModule",
    },
    {
        path: "user",
        loadChildren: "./users/users.module#UsersModule",
    },
    {
        path: "athlete",
        loadChildren: "./athletes/athletes.module#AthletesModule",
    },
    {
        path: "sport/:id",
        loadChildren: "./sports/sports.module#SportsModule",
    },
    {
        path: "staff",
        loadChildren: "./staffs/staffs.module#StaffsModule",
    },
    {
        path: "attendant",
        loadChildren: "./attendants/attendants.module#AttendantsModule",
    },
    {
        path: "participant",
        loadChildren: "./participants/participants.module#ParticipantsModule",
    },
    {
        path: "accommodation",
        loadChildren:
            "./accommodations/accommodations.module#AccommodationsModule",
    },
    {
        path: "qr",
        loadChildren: "./qrcode/qrcode.module#QrcodeModule",
    },
    {
        path: "stadium",
        loadChildren: "./stadium/stadium.module#StadiumModule",
    },
    {
        path: "report",
        loadChildren: "./reports/reports.module#ReportsModule",
    },
    { path: "500", component: InternalErrorComponent },
    { path: "401", component: UnauthorizedComponent },
    { path: "404", component: NotfoundComponent },
    { path: "", redirectTo: "dashboard", pathMatch: "full" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
