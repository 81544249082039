import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { Router } from "@angular/router";
import { catchError, map, tap } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import {
    of as observableOf,
    throwError as observableThrowError,
    Observable,
} from "rxjs";
import { AppConfigService } from "../app-config.service";
import { TokenService } from "./../shared/token.service";

@Injectable({
    providedIn: "root",
})
export class UsersService {
    public api: any;
    public httpAuthHeaders: any = null;

    constructor(
        private config: AppConfigService,
        private http: HttpClient,
        private localStorage: LocalStorage,
        private router: Router,
        private toastr: ToastrService,
        private tokenService: TokenService,
        private permissionsService: NgxPermissionsService
    ) {
        this.api = this.config.load();
        this.setAuthHeader();
        // this.api = this.config.getAll();
    }

    setAuthHeader() {
        this.localStorage.getItem("user").subscribe((data) => {
            this.httpAuthHeaders = {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + data,
                }),
            };
        });
    }

    /**
     * Get a user token from the api
     *
     * @param data Login form data
     */
    login(data): Observable<any> {
        data.grant_type = this.api.grant_type;
        data.client_id = this.api.client_id;
        data.client_secret = this.api.client_secret;

        const url = this.api.base + "v1/oauth/token";
        const httpOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/json" }),
        };

        return this.http.post(url, data, httpOptions).pipe(
            map((res) => {
                return res;
            }),
            catchError((err) => {
                if (err.status === 401) {
                    this.toastr.error(
                        "Invalid login",
                        "กรุณาตรวจสอบ ชื่อผู้ใช้หรือรหัสผ่าน"
                    );
                }

                return this.handleError(err);
            })
        );
    }

    logout() {
        this.tokenService.clearToken();
        this.permissionsService.flushPermissions();
    }

    /**
     * Store user token
     * @param token user's token
     */
    storeToken(token) {
        this.tokenService.storeToken(token);
        this.getUser(token).subscribe((res) => {
            this.setupPermissions(res);
        });
    }

    getUsers(): Observable<any> {
        const url = this.api.base + "v1/users";
        const httpOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/json" }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    editUser(id: number, data: any): Observable<any> {
        const url = `${this.api.base}v1/admin/users/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.post(url, data, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserById(id: number): Observable<any> {
        const url = `${this.api.base}v1/admin/users/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserAccommodation(): Observable<any> {
        const url = this.api.base + "v1/user/accommodation";
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getAccommodationDetail(accommodationId, userId): Observable<any> {
        const url =
            this.api.base +
            `v1/accommodations/${accommodationId}/users/${userId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUser(token): Observable<any> {
        const url = this.api.base + "v1/user";
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserAthletSports(id: number, token: string): Observable<any> {
        const url = `${this.api.base}v1/user/athletes/sports/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserSports(token): Observable<any> {
        const url = this.api.base + "v1/user/sports";
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserSportsById(id): Observable<any> {
        const url = this.api.base + `v1/sports?user_id=${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserSportCategory(token, id): Observable<any> {
        const url = `${this.api.base}v1/user/sports/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getUserSportCategoryById(userId, sportId): Observable<any> {
        const url = `${this.api.base}v1/sports/${userId}?user_id=${sportId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    getAthletInCategory(token, categoryId, sportId): Observable<any> {
        const url = `${this.api.base}v1/user/sports/${sportId}/categories/${categoryId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    uploadPhoto(token, file, id): Observable<any> {
        const url = `${this.api.base}v1/user/athletes/${id}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: "Bearer " + token,
            }),
        };

        const formData = new FormData();
        formData.append("img", file);

        return this.http.post(url, formData, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    checkExists(token, idCard, categoryId): Observable<any> {
        const url = `${this.api.base}v1/user/athletes/checkexists?id=${idCard}&category_id=${categoryId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: "Bearer " + token,
            }),
        };

        return this.http.get(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    deleteAthlet(token, sportId, categoryId, athleteId): Observable<any> {
        const url = `${this.api.base}v1/user/sports/${sportId}/categories/${categoryId}/athletes/${athleteId}`;
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem("user")}`,
            }),
        };

        return this.http.delete(url, httpOptions).pipe(
            map((res) => res),
            catchError(this.handleError)
        );
    }

    public setupPermissions(user: any) {
        const permissions = [];

        this.permissionsService.flushPermissions();

        if (user && user.id) {
            if (user.id === 297) {
                permissions.push("ADMIN");
            } else if (user.id === 298) {
                permissions.push("EDITOR");
            } else {
                permissions.push("GUEST");
            }
        } else {
            permissions.push("GUEST");
        }

        this.permissionsService.loadPermissions(permissions);
    }

    /**
     * Handle error from the request.
     * @param error error handle
     */
    private handleError(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        let errMsg: string;

        if (error instanceof Response) {
            const body = error.json() || "";
            const err = JSON.stringify(body);
            console.error("ERROR!", err);
            errMsg = `${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        this.tokenService.clearToken();
        this.permissionsService.flushPermissions();

        return observableThrowError(errMsg);
    }
}
