// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    // whitelist: ["https://api.siamplus.co.th/"],
    // api: "https://api.siamplus.co.th/v1/",
    // base: "https://api.siamplus.co.th/",
    // public_token:
    //     "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzhmZDFlMzcyMzkyY2MwMDE3ODEwODExZDMxMDdlYjI0ZGNiNzgzNjcyMDYzNGY5MGNlMDliMmQ4NGFiODVkNTk4NWQ0NGZlMTM5ZDMzYjMiLCJpYXQiOjE3MzM5NjM5NDYuNjYzNzc5LCJuYmYiOjE3MzM5NjM5NDYuNjYzNzgxLCJleHAiOjE3NjU0OTk5NDYuNjU2NTAxLCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.b9UvxrgywnlUNNVQqzUxZPaEoylKawTBkc-xzQDwHCNfQwqhYuxVWi_AUcf0Uo8aKpl6DUeaXVCMH364myvvMvlBJKEZYdvcHNZ2fDaFDl3_lBNLuBZATBMLCkNkIISEYv6eDPgKBv3FkmQcv7avSTdEaYeqiZweyoLIeyQ6yHXEHkIILzoBStkfOaAXRIdZ36GJYnTpmd5E7jpAAU3nbM7xUUokzqhPdWcB0g0sz0BJdjhqO9DEZygLtvCGrks-lkIL6b9kdqZidbZPLTHPb-l74Pj2MP9NsyUkUFZJn1q-8KMsRYDqQN_AL425oot93a-xuR642XCOVGaKlX2W6qvOL5PtyoPLLVeOh4x5ttb3Ju-JeMqNUtvrKQIMa3jlKakP3YFDljWZPXmgUznoPng87EutLwd5V1iEWU0BOdlrLCi2NCrpej7Oyo4EZkWX9ajy62pK0VMuL54PbKmpsLAhKzpL62SE3SYr2akIrHwjwJo-P6DamrBwKGozMWdEAsm7qIdxXnbB25M0WfoXKSilArJeD9byw4X5Yu5Ny-sFlzX4IuYIaQp1mfyb2mVTLjRkVaMc7hTGzr1AK3DCwPzS2LFS1htqXxDOm7HJRgVMa83lUEzMZszJivv_2Rwr7cQhZbnxuKNG0yg121CT-hN9DkOrx-wuZIFnuSG51Cw",
    whitelist: ["https://api.nakhonsawan-pasangames.com/"],
    api: "https://api.nakhonsawan-pasangames.com/v1/",
    base: "https://api.nakhonsawan-pasangames.com/",
    public_token:
        "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMzU1ZjhhMDUwZGE4NTA5NDYwZmQ3YWZlODc3NDI2MTRmYzQyY2U1YzM1NjBlNzdkYzFjZmM0YmMxYjFmZWI5MjlhNGVkZTQ1MTA1MzM1ZGYiLCJpYXQiOjE3Mjk0MDA3MTkuODM1MTQ4LCJuYmYiOjE3Mjk0MDA3MTkuODM1MTUxLCJleHAiOjE3NjA5MzY3MTkuODI3NTU0LCJzdWIiOiIyOTkiLCJzY29wZXMiOltdfQ.d95BSPMP9CwQrNfs5heT92vF2imGXdlkMk3sU_7c1JnNpmoHnkB9tJ7AiFXrNpIPMAUjzPPuGJyOWgbeC7nHrcczSKl-E_YF10YPZXBsdjhc-ClU1y0kHBRBh-nNYd0JMAh2eZjrxHWGc_PHIFbaXgIIaZh-vAsWsE9HYWEsON-ZLbyWGilYLtyRzHmxd979AqsfAqrPYwmDawW6tKrqXAlbDHcRoQuF7UgOpiWGvu2YwYl966vmn_onFIjBGRuZEOusfBdyWCoZpnchX8W3dT1TR08YQtCkDwP1Y1o6sstHmOYsdZ5IGfJ2Gq-i01pJ2eK4Ei8fNpvcHslGiQ1fsHj04EBKWXqfD3MLqbQgC_EbH6F1OESMf8Ly7q4i0nEhxo59cNdr0pVKUc7jAi-r0kjMmv-hd0GrYCPdoB3a6M3w5AIEDJhOboGRMlGK6FHJlUlklIxLHZyc3yIuHDxY10ffiTQpL3SJLvabZwM7QXS5roP_OmkiFbhf1ESFCEzQBKilh-HrqPcft33qiMnddTZUxVgxg6y04I6yg_eOXpMUx-4fQqT7KIZz9r7umszMDB6n2PKdvXLNZtlf-eyg_gVfnoqjMbUrnfZ2XUZT1kQTEgroGnQLfs2RGtRU3SGB_xNn5WkPGdqknnCzEN49rDIwKi3qHrQGKGJVa-YS4qM",
    grant_type: "password",
    client_id: 1,
    client_secret: "AiDEuKRotDn9uDbdOewzhpjEHHtERMUCSM5eNgoE",
    is_disable_id_print: false,
    is_disable_certificate: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
